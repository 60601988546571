import React from 'react';
import {Script} from "gatsby";
import {useDataLang} from "../../context/LangContext";


const FORM_ID_MAP = {
    pl: "68780c35-634e-4395-802d-178da0d188d4",
    en: "f7cb2c45-0090-42d7-b606-1704a20152c7"
}

import * as styles from './ConsultationForm.module.scss';

export function ConsultationForm({id}) {
    const currentLang = useDataLang();
    const formId = FORM_ID_MAP[currentLang];
    return <>
        <Script id="hubsport-forms" src="//js-eu1.hsforms.net/forms/embed/v2.js" onLoad={() => {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25085162",
                formId,
                target: `#${id}`
            });
        }}/>
        <div className={styles.root} id={id}></div>
    </>
}
